/*****************************************************************************/
/*
/* Common
/*
/*****************************************************************************/

/* Global Reset */

* {
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
}

body {
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", 
    "Fira Sans", "Droid Sans", "Helvetica Neue", 
    sans-serif;
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

h1 {
  font-size: 130%;
  padding-bottom: 2px;
  border-bottom: 4px solid #bbb;
  margin-top: 2em;
  margin-bottom: 1em;
}

h2 {
  font-size: 120%;
  padding-bottom: 2px;
  border-bottom: 2px solid #ddd;
  margin-top: 2em;
  margin-bottom: 1em;
}

h3 {
  font-size: 110%;
  margin-top: 2em;
  margin-bottom: 1em;
}

h4, h5, h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

p {
  margin: 1em 0;
}

table {
  font-size: inherit;
  font: 100%;
}

/*****************************************************************************/
/*
/* Contents page(s) links
/*
/*****************************************************************************/

#contents a {
  color: #000;
}

#contents a:link {
    text-decoration: none;
}

#contents a:visited {
    text-decoration: none;
}

#contents a:hover {
    text-decoration: underline;
}

#contents a:active {
    text-decoration: underline;
 }

/*****************************************************************************/
/*
/* Contents list on "Blog" page
/*
/*****************************************************************************/

#contents ul.posts {
  list-style-type: none;
  margin-bottom: 1em;
}

#contents ul.posts li {
  line-height: 1.75em;
}

#contents ul.posts span {
  color: #aaa;
  font-size: 80%;
}


/*****************************************************************************/
/*
/* Contents list on "Guides" page
/*
/*****************************************************************************/

#contents ul.guides {
  list-style-type: none;
  margin-bottom: 4em;
}

#contents ul.guides li {
  line-height: 1.25em;
  margin-bottom: 1.5em;
}

#contents ul.guides .description {
  color: #444;
  font-weight: normal;
  font-size: 75%;
}



/*****************************************************************************/
/*
/* Site
/*
/*****************************************************************************/

.site {
  max-width: 1024px;    /* Set max page width for readability */
  text-align: justify;
  width: 90%;
  margin: 3em auto 2em auto;
  line-height: 1.5em;
}

.header {
  color: #a00;
  font-weight: bold;
  margin-bottom: 2em;
}

.site .header a {
  float: left;
  color: #a00;
  text-decoration: none;
}

.site .header a:hover {
  color: black;
 }


/*****************************************************************************/
/*
/* Navigation header
/*
/*****************************************************************************/

.site .header .site-nav li a.active {
  transition: 25ms ease-out;
  color: #555;
}

.site .header .site-nav li a:hover {
  transition: 25ms ease-out;
  color: #555;
}

.site .header .site-nav li a{
  float: right; 
  text-align: center;
  color: #aaa;
  text-decoration: none;
  margin-left: 1em;
}

.site .header .site-nav ul {
  list-style-type: none;
}


/* Search box */
/* ---------- */

input.form-control {
    width: 160px;
    font-size: 90%; 
    color: #555;
    border-radius: 5px;
    border: 1px #aaa solid;
    padding: 3px 25px 3px 5px;
    background-clip: padding-box; /*Get rid of ugly iOS shadow */
}

textarea:focus, input:focus{
  outline: none;
  border: 1px #444 solid;
}

.site .search {
    position: relative;
    margin-left: 1em;
    float: right; 
}

.site .btn {
    position: absolute;
    top: 2px;
    right: 4px;
    width: 1.0em;
    height: 1.0em;
    font-size: 1.3em;
    border: none;
    background-color: white;
    fill: #aaa;
}

.site .btn:hover {
    fill: #444;
}




/*****************************************************************************/
/*
/* Footer
/*
/*****************************************************************************/

.site .footer {
  border-top: 0px solid #ccc;
  margin: 3em auto 3em auto;
  padding-bottom: 6em;
  width: 100%; 
  overflow: hidden; 
}

.site .footer .float_center {
  float: right;
  position: relative;
  left: -50%; /* or right 50% */
  text-align: left;
}

.site .footer .float_center > .child {
  position: relative;
  left: 50%; 
}

.site .footer ul { 
  list-style-type: none;
  margin:0;
  padding: 0 0;
}

.site .footer ul li {
  float: left;
  list-style-type: none;
  margin: 0em 1.5em 0em 0em;
}



/*****************************************************************************/
/*
/* Footnotes
/*
/*****************************************************************************/

.site .footnotes {
  border-top: 2px solid #ccc;
  margin: 3em auto 3em auto;
  width: 100%; 
  overflow: hidden; 
}


/*****************************************************************************/
/*
/* Posts
/*
/*****************************************************************************/

#post {

}

#post a{
  color: #a00;
}

#post a:link {
    text-decoration: none;
}

#post a:visited {
    text-decoration: none;
}

#post a:hover {
    text-decoration: underline;
}

#post a:active {
    text-decoration: underline;
}

/*****************************************************************************/
/*
/* Images, with captions
/*
/*****************************************************************************/


#post img,          /* needed for old article compatability */
#post figure img{
    display: block; 
    width: auto;
    max-width: 100%;
    margin: 1em auto 0em;
    border: 1px solid #444;
}

#post figure figcaption{
    width: auto;
    max-width: 100%;
    color: #aaa;
    font-size: smaller;
    font-style: italic;
    text-align: center;
}





#post ul,
#post ol {
    margin-bottom: 0em; /* was 1.5em on 6 Feb 2017 */
    margin-left: 1.35em;
}


/*****************************************************************************/
/*
/* Blockquotse
/*
/*****************************************************************************/

blockquote {
    margin: 1em 2em;
    padding: 1em 1em;
    font-size: 100%;
    font-style: italic; 
    background-color: #aaa;
    color: #fff;

    &.danger {
      background-color: #a00;
      color: #fff;
    }

    &.warning {
      background-color: #ffe932;
      color: #222;
    }

    &.notice {
      background-color: #39e939;
      color: #222;
    }
}
/* this stops additoinal spacing being applied inside blockquote*/
blockquote p {
  display: inline;
}



/*****************************************************************************/
/*
/* Code snippets
/*
/*****************************************************************************/

pre {
    overflow: auto;
    padding: 1em;
    border: 1px solid #ddd;
  }


code {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 90%;
    background-color: #f7f7f7;
    color: #586e75;
    padding: 0.2em 0.5em;
    letter-spacing: -0.02em;
  }

pre code {
      color: #586e75;
      padding: 0em;
}


/*****************************************************************************/
/*
/* Table 
/*
/*****************************************************************************/

#post table { border-collapse: collapse; text-align: left; width: 100%; font-size: 50%; background: #fff; overflow: hidden; border: 1px solid #8C8C8C; }
#post table td, #post table th { padding: 3px 10px; }
#post table thead th {background-color:#8C8C8C; color:#FFFFFF; font-size: 15px; font-weight: bold; border-left: 1px solid #A3A3A3; }
#post table thead th:first-child { border: none; }
#post table tbody td { color: #7D7D7D; border-left: 1px solid #DBDBDB;font-size: 12px;font-weight: normal; }
#post table tbody .alt td { background: #EBEBEB; color: #7D7D7D; }
#post table tbody td:first-child { border-left: none; }
#post table tbody tr:last-child td { border-bottom: none; }
#post table tbody tr:nth-child(2n) td {background-color:#EEEEEE;}


/*****************************************************************************/
/*
/* Terminal
/*
/*****************************************************************************/

#post pre.terminal {
  border: 1px solid black;
  background-color: #333;
  color: white;
}

#post pre.terminal code {
  background-color: #333;
}

#related {
  margin-top: 2em;
}

#related h2 {
  margin-bottom: 1em;
}

#post h2 {
  padding: 1em, 0, 2em, 0;
}



/*****************************************************************************/
/*
/* pfSense styling  for use with font awesome icons 
/*
/*****************************************************************************/

.pfsense_blue {
  color: #337AB7;
}

.pfsense_red {
  color: #B71C1C;
}


 


/*****************************************************************************/
/*
/* Social sharing buttons
/*
/*****************************************************************************/

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  font-size: 1em; 
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.6em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1.5em;
  height: 1.5em;
  margin-right: 0em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--rss {
  background-color: #f26522;
}

.resp-sharing-button--search {
  background-color: #5f99cf;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
  border-color: #dd4b39;
}

.resp-sharing-button--google:hover,
.resp-sharing-button--google:active {
  background-color: #c23321;
  border-color: #c23321;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--rss {
  background-color: #f26522;
  border-color: #f26522;
}

.resp-sharing-button--rss:hover,
.resp-sharing-button--rss:active {
  background-color: #ee5500;
  border-color: #ee5500;
}

.resp-sharing-button--search {
  background-color: #5f99cf;
  border-color: #5f99cf;
}

.resp-sharing-button--search:hover,
.resp-sharing-button--search:active {
  background-color: #3a80c1;
  border-color: #3a80c1;
}



/*****************************************************************************/
/*
/* Paypal
/* http://jsfiddle.net/rbsthlm/qE9kJ/ 
/*****************************************************************************/

.left-inner-addon {
    position: relative;
}
.left-inner-addon input {
    padding-left: 20px;    
}
.left-inner-addon span{
    position: absolute;
    padding: 0px 8px;
    pointer-events: none;
}

.paypal_btn {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 3px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  transition-duration: 0.4s;
}
.paypal_btn:hover {
  background-color: #3b9F50; /* Greenish */
  color: white;
}

.donation-crypto ul {
  list-style-type: none;
  margin-bottom: 4em;
}

.donation-crypto ul li {
  line-height: 1.25em;
  margin-bottom: 0.25em;
}




/*****************************************************************************/
/*
/* Clearfix
/*
/*****************************************************************************/

.clearfix:before, .clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}






